/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const Photo = createSvgIcon(
    <>
        <path
            d="M22.462 19h15.076c1.382 0 2.47 0 3.32.114.873.118 1.57.363 2.117.911.548.548.793 1.244.91 2.117.115.85.115 1.938.115 3.32v10.076c0 1.382 0 2.47-.114 3.32-.118.873-.363 1.57-.911 2.117-.548.548-1.244.793-2.117.91-.85.115-1.938.115-3.32.115H22.462c-1.382 0-2.47 0-3.32-.114-.873-.118-1.57-.363-2.117-.911-.548-.548-.793-1.244-.91-2.117C16 38.008 16 36.92 16 35.538V25.462c0-1.382 0-2.47.114-3.32.118-.873.363-1.57.911-2.117.548-.548 1.244-.793 2.117-.91.85-.115 1.938-.115 3.32-.115zm-3.187 1.105c-.762.103-1.212.296-1.543.627-.33.331-.524.781-.627 1.543-.104.776-.105 1.797-.105 3.225v10c0 1.428.001 2.45.105 3.225.103.762.296 1.212.627 1.543.331.33.781.524 1.543.627.776.104 1.797.105 3.225.105h15c1.428 0 2.45-.001 3.225-.105.762-.103 1.212-.296 1.543-.627.33-.331.524-.781.627-1.543.104-.776.105-1.797.105-3.225v-10c0-1.428-.001-2.45-.105-3.225-.103-.762-.296-1.212-.627-1.543-.331-.33-.781-.524-1.543-.627C39.95 20.001 38.928 20 37.5 20h-15c-1.428 0-2.45.001-3.225.105z"
            fill="#fff"
        />
        <path
            d="M31.117 16h3.765c.505 0 .917 0 1.258.032.356.033.67.103.97.264.3.16.532.382.757.66.215.266.444.609.724 1.029l.825 1.238-.832.554-.813-1.219c-.295-.443-.499-.747-.681-.973-.176-.217-.31-.332-.453-.408-.142-.076-.312-.124-.59-.15C35.758 17 35.392 17 34.859 17h-3.718c-.533 0-.9 0-1.188.027-.279.026-.448.074-.59.15-.142.076-.277.191-.453.409-.182.225-.386.529-.681.973l-.813 1.218-.832-.554.825-1.238c.28-.42.509-.763.724-1.03.225-.277.458-.499.758-.66.3-.16.613-.23.97-.263.34-.032.752-.032 1.256-.032zM30 26.1a4.9 4.9 0 100 9.8 4.9 4.9 0 000-9.8zM23.9 31a6.1 6.1 0 1112.2 0 6.1 6.1 0 01-12.2 0z"
            fill="#fff"
        />
        <path d="M40 24.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" fill="#fff" />
    </>,
    'Photo'
)

export default Photo
