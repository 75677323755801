/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const Newsletter = createSvgIcon(
    <>
        <path
            fill="#E20177"
            d="M21.33 42.35l-2.24-3.87-3.52 2.03a.81.81 0 11-.8-1.4l3.5-2.04-.57-1-6.08.03a.81.81 0 01-.7-1.22l3.06-5.25-3-5.22-3.5 2.07a.81.81 0 11-.82-1.4L10.18 23a3.66 3.66 0 011.52-4.65L39.14 2.51a3.66 3.66 0 015 1.34l10.96 19a3.66 3.66 0 01-1.34 5L26.32 43.68a3.66 3.66 0 01-5-1.34zm21.4-37.69a2.03 2.03 0 00-2.78-.74L12.51 19.76a2.03 2.03 0 00-.75 2.78l.01.01 17.78 2.7a6.14 6.14 0 006.64-3.82l6.54-16.75v-.02zm1.04 1.82l-5.67 14.5 14.63 1-8.96-15.5zm-18.26 35.8l27.44-15.85a2.03 2.03 0 00.76-2.75l-16.25-1.11a7.75 7.75 0 01-7.55 4.36l-7.16 14.63a2.03 2.03 0 002.76.72zm-6.64-7.43l.82 1.4 1.4-.8a.81.81 0 11.82 1.4l-1.41.82 1.27 2.2L28.2 26.7l-15.4-2.35 2.81 4.87a.81.81 0 010 .82l-2.58 4.43 5.13-.03a.81.81 0 01.7.41z"
        />
        <path
            fill="#E20177"
            d="M4.1 30.39l5.63-3.25a.81.81 0 01.81 1.4L4.91 31.8a.81.81 0 01-.81-1.4zM12.16 44.35l5.63-3.25a.81.81 0 01.82 1.41l-5.63 3.25a.81.81 0 11-.82-1.4zM39.71 30.32l.7-.4a.81.81 0 11.82 1.4l-.7.41a.81.81 0 11-.82-1.4zM43.23 28.3l2.82-1.63a.81.81 0 11.81 1.4l-2.81 1.63a.81.81 0 01-.82-1.4z"
        />
    </>,
    'Newsletter'
)

export default Newsletter
