/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const SocialFacebook = createSvgIcon(
    // <svg width="123" height="25" viewBox="00 0 103 25" left="-32px">
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.155 10.656L18.649 10.657C17.468 10.657 17.239 11.218 17.239 12.041V13.857H20.056L19.689 16.702H17.239V24H14.302V16.702H11.846V13.857H14.302V11.76C14.302 9.325 15.789 8 17.96 8C19 8 19.894 8.077 20.155 8.112V10.656ZM16 0C7.164 0 0 7.163 0 16C0 24.836 7.164 32 16 32C24.837 32 32 24.836 32 16C32 7.163 24.837 0 16 0Z"
    />,
    'SocialFacebook'
)

export default SocialFacebook
