/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const Two = createSvgIcon(
    <path
        d="M10 20a9.935 9.935 0 01-7.071-2.929A9.935 9.935 0 010 10a9.935 9.935 0 012.929-7.071A9.935 9.935 0 0110 0a9.935 9.935 0 017.071 2.929A9.935 9.935 0 0120 10a9.935 9.935 0 01-2.929 7.071A9.935 9.935 0 0110 20zm0-18.438c-4.652 0-8.438 3.786-8.438 8.438 0 4.652 3.786 8.438 8.438 8.438 4.652 0 8.438-3.785 8.438-8.438 0-4.652-3.785-8.438-8.438-8.438zm3.901 12.276c-.058-.091-1.452-2.236-3.94-2.236s-3.882 2.145-3.94 2.236a.781.781 0 101.318.84c.01-.016.982-1.514 2.622-1.514s2.612 1.498 2.622 1.513a.781.781 0 101.318-.839zM6.563 6.445a.977.977 0 110 1.953.977.977 0 010-1.953zm5.859.977a.977.977 0 101.953 0 .977.977 0 00-1.953 0z"
        fill="#000"
    />,
    'Two'
)

export default Two
