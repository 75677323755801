/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const SocialInstagram = createSvgIcon(
    <>
        <path d="M16 18.8095C14.4483 18.8095 13.1905 17.5517 13.1905 16C13.1905 14.4483 14.4484 13.1905 16 13.1905C17.5517 13.1905 18.8095 14.4483 18.8095 16C18.8095 17.5516 17.5516 18.8095 16 18.8095Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.1004 19.5928C23.1004 21.53 21.53 23.1004 19.5928 23.1004H12.4072C10.47 23.1004 8.89956 21.53 8.89956 19.5928V12.4072C8.89956 10.47 10.47 8.89956 12.4072 8.89956H19.5928C21.53 8.89956 23.1004 10.47 23.1004 12.4072V19.5928ZM16 11.402C13.4647 11.402 11.402 13.4647 11.402 16C11.402 18.5353 13.4647 20.5979 16 20.5979C18.5353 20.5979 20.598 18.5353 20.598 16C20.598 13.4646 18.5353 11.402 16 11.402ZM21.7086 11.4366C21.7086 12.045 21.2154 12.5383 20.6069 12.5383C19.9984 12.5383 19.5051 12.045 19.5051 11.4366C19.5051 10.8281 19.9984 10.3348 20.6069 10.3348C21.2154 10.3348 21.7086 10.8281 21.7086 11.4366Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM24.8889 12.4072C24.8889 9.48693 22.513 7.11111 19.5928 7.11111H12.4072C9.48693 7.11111 7.11111 9.48693 7.11111 12.4072V19.5928C7.11111 22.5131 9.48693 24.8889 12.4072 24.8889H19.5928C22.5131 24.8889 24.8889 22.5131 24.8889 19.5928V12.4072Z"
        />
    </>,
    'SocialInstagram'
)

export default SocialInstagram
