import { useCallback, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

import { useNavigationStore } from '../context/navigation'
import createIsolatedHook from '../helpers/createIsolatedHook'
import useGTM from './useGTM'
import { useRouteChangeComplete } from './useRouteChange'
/**
 * useBaseLayout - use only once in _app.js
 * connects isTransitioning, hasNavigated & hasNavigationStarted redux flags with Next.js navigation events
 * connscts automatic closing of Navigation, Search and Filters
 */
const useBaseLayout = () => {
    const router = useRouter()

    useEffect(() => {
        const onRouteChangeComplete = () => {
            // @NOTE: what is this? Group it in proper context
            try {
                window?.dm?.AjaxEvent('pageview')
            } catch (error) {
                // Ignore metric errors
            }
        }

        router.events.on('routeChangeComplete', onRouteChangeComplete)
        router.events.on('routeChangeError', onRouteChangeComplete)

        return () => {
            router.events.off('routeChangeComplete', onRouteChangeComplete)
            router.events.off('routeChangeError', onRouteChangeComplete)
        }
    }, [router.events])
    const [, setNavigation] = useNavigationStore()

    const handleRouteChangeComplete = useCallback(() => {
        setNavigation({ isOpen: false, searchIsOpen: false, filtersIsOpen: false })
    }, [setNavigation])
    useRouteChangeComplete(handleRouteChangeComplete)
}
export const useBaseLayoutScroll = () => {
    const { sendDataToGTM } = useGTM()
    const router = useRouter()
    const scrollEnabled = useRef(false)
    const previousPosition = useRef(false)
    // @NOTE: why are we tracking clicks? Tf is this?
    useEffect(() => {
        if (typeof window === 'undefined') {
            return () => {}
        }
        const printMousePos = e => {
            if (e.pageY - window.pageYOffset > 40) {
                previousPosition.current = window.pageYOffset
            }
        }
        document.addEventListener('click', printMousePos, true)

        return () => {
            document.removeEventListener('click', printMousePos, true)
        }
    }, [])

    const onRouteChangeComplete = useCallback(() => {
        if (scrollEnabled.current && typeof window !== 'undefined') {
            window.scrollTo(0, previousPosition.current)
            scrollEnabled.current = false
        }
    }, [])
    const onBeforePopState = useCallback(() => {
        // @NOTE: gtm pushstate needs to be fired when going back
        if (typeof window !== 'undefined') {
            const reverseDataLayer = window.dataLayer?.reverse() || []
            const indexOfPop = reverseDataLayer?.findIndex(item => item['gtm.historyChangeSource'] === 'popstate')
            if (indexOfPop !== -1) {
                sendDataToGTM({ ...reverseDataLayer[indexOfPop], 'gtm.historyChangeSource': 'pushstate' })
            }
        }
        scrollEnabled.current = true
        return true
    }, [])
    useEffect(() => {
        router.beforePopState(onBeforePopState)
        router.events.on('routeChangeComplete', onRouteChangeComplete)
        return () => {
            router.events.off('routeChangeComplete', onRouteChangeComplete)
        }
    }, [router, onRouteChangeComplete, onBeforePopState])
}
export default useBaseLayout
export const IsolatedUseBaseLayout = createIsolatedHook(useBaseLayout)
export const IsolatedUseBaseLayoutScroll = createIsolatedHook(useBaseLayoutScroll)
