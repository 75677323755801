/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'
// viewBox="0 0 10 11"
const ReadMore = createSvgIcon(
    <path fillRule="evenodd" clipRule="evenodd" d="M10 2H0V0H10V2ZM10 5H0V3H10V5ZM10 8H0V6H10V8ZM6 11H0V9H6V11Z" />,
    'ReadMore'
)

export default ReadMore
