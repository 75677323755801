/* eslint-disable @typescript-eslint/naming-convention */

import { TaxonSitemap } from '../../next/data-fetching/elasticsearch'
import { MergeTypes } from '../helpers/MergeTypes'
import { ProcessedEntityMetadata, ProcessedStructuredData } from '../seo'

export type AuthorTaxon = {
    id: string
    title: string
    slug: string
    author_id: string
    content_taxonId: string
}
export type EntityAutor = {
    name: string
    url: string
    image: string
}
export type EntityElastic = {
    id: string
    type: EntityType
    structuredData: ProcessedStructuredData
    metadata: ProcessedEntityMetadata
    body: string
    title: string
    name: string
    authors?: EntityAutor[]
    taxons?: {
        [key: string]: any
        authors?: AuthorTaxon[]
    }
    title_original?: string
    year?: string
    short_description?: string
    [key: string]: any
}

/**
 * @Note Might be wrong, recheck with API response
 */
export type PublishedState = 'published' | 'unpublished' | 'scheduled'

export type Color = {
    percentage: number
    hex: string
}
export type ImageElastic = {
    original_file_type: string
    updated_at: string
    original_url: string
    name: string
    original_aspect_ratio: number
    id: string
    state: string
    colors: Color[]
}

export interface SitemapNode {
    id: string
    title: string
    href: string
    categoryColor: string
    gemiusId: string
}
export type ArticleEntityElastic = MergeTypes<
    EntityElastic,
    {
        image: ImageElastic
        author: AuthorTaxon
        body: string
        owned_by_user_profile_id: string
        title: string
        lead: string
        content: string
        state: PublishedState
        sitemap: {
            href: string
            node: SitemapNode
        }
    }
>
export type VoyoSlide = {
    image: {
        id: string
    }
    title: string
    active: boolean
    url?: string
    subtitle?: string
    show_from: string
    show_until: string
}
type VoyoSliderExtendedAttributes = {
    play_widget_slides: VoyoSlide[]
    play_widget_button_title: string
    play_widget_button_url: string
}
export type VoyoSliderElastic = MergeTypes<
    EntityElastic,
    {
        image: {
            id: string
        }
        extended_attributes: VoyoSliderExtendedAttributes
    }
>
export const singleEntities = [
    'article',
    'column',
    'counseling',
    'gallery',
    'giveaway',
    'movie',
    'product',
    'recipe',
    'show',
    'video',
    'image-article' // hot-or-not, hunk-of-the-day
] as const
export const listingEntities = [
    'article-listing',
    'author-listing',
    'author',
    'category-archive',
    'category',
    'columnist-listing',
    'columnist',
    'counseling-listing',
    'gallery-listing',
    'giveaway-listing',
    'image-article-listing',
    'image-article-score',
    'latest',
    'product-listing',
    'recipe-listing',
    'tag',
    'video-listing'
] as const

export const generalEntityTypes = ['general', 'product-reviews', 'product-scores', 'search', 'sitemap'] as const

export type EntityType =
    | 'default'
    | (typeof singleEntities)[number]
    | (typeof listingEntities)[number]
    | (typeof generalEntityTypes)[number]
    | 'loading'

export const isSingleEntity = (type: EntityType) => (singleEntities as unknown as EntityType[]).includes(type)
export const isListingEntity = (type: EntityType) => (listingEntities as unknown as EntityType[]).includes(type)
export const isGeneralEntity = (type: EntityType) => (generalEntityTypes as unknown as EntityType[]).includes(type)

export type GeneralType = 'general' | 'single' | 'listing'

export const entityTypeToGeneralType = (type: EntityType) => {
    if (isSingleEntity(type)) return 'single'
    if (isListingEntity(type)) return 'listing'
    return 'general'
}

// ----
// The code bellow is use in getOneById
// ----

type ElasticEntityCommon = {
    id: string
    taxons: {
        'article-type':
            | undefined
            | {
                  slug: string
              }[]
        sitemap: TaxonSitemap[]
    }
    has_children: boolean
    children: any
}

type ElasticArticle = ElasticEntityCommon & {
    type: 'article'
}

type ElasticSitemap = ElasticEntityCommon & {
    type: 'sitemap'
}

type ElasticShow = ElasticEntityCommon & {
    type: 'show'
}

type ElasticVideo = ElasticEntityCommon & {
    type: 'video'
    original_url: string
    custom_manifest_url: string
}

type ElasticUser = ElasticEntityCommon & {
    type: 'user'
}

type ElasticTaxon = ElasticEntityCommon & {
    type: 'taxon'
}

export type ElasticEntity = {
    found: boolean
    _source: ElasticArticle | ElasticSitemap | ElasticShow | ElasticVideo | ElasticUser | ElasticTaxon
}
