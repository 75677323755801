/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const Quote = createSvgIcon(
    <path d="M21.5083 11.549c-1.8533 1.0647-3.3518 1.9914-4.4954 2.78-1.1435.7493-2.3068 1.7548-3.4898 3.0167-1.1041 1.183-1.9519 2.4251-2.5434 3.7264-.5915 1.3013-1.00558 2.9772-1.24218 5.0277h2.83918c2.4449 0 4.3574.6112 5.7375 1.8337 1.4196 1.183 2.1294 2.9377 2.1294 5.2643 0 1.6562-.6309 3.2138-1.8928 4.6728C17.3284 39.2902 15.6328 40 13.464 40c-3.3913 0-5.81643-1.1041-7.27546-3.3124C4.72951 34.4399 4 31.5022 4 27.8743c0-2.5631.55206-4.8897 1.65619-6.9797 1.10413-2.1293 2.44486-4.0221 4.02218-5.6783 1.61673-1.6957 3.33213-3.135 5.14603-4.318C16.6383 9.71534 18.1565 8.74923 19.3789 8l2.1294 3.549zm21.8854 0c-1.8533 1.0647-3.3518 1.9914-4.4954 2.78-1.1435.7493-2.3068 1.7548-3.4898 3.0167-1.1436 1.2224-2.0111 2.4842-2.6026 3.7855-.552 1.2619-.9464 2.9181-1.183 4.9686h2.8392c2.4449 0 4.3574.6112 5.7375 1.8337 1.4196 1.183 2.1294 2.9377 2.1294 5.2643 0 1.6562-.6309 3.2138-1.8928 4.6728C39.2138 39.2902 37.5182 40 35.3494 40c-3.3913 0-5.8164-1.1041-7.2755-3.3124-1.459-2.2477-2.1885-5.1854-2.1885-8.8133 0-2.5631.5521-4.8897 1.6562-6.9797 1.1041-2.1293 2.4448-4.0221 4.0222-5.6783 1.6167-1.6957 3.3321-3.135 5.146-4.318C38.5237 9.71534 40.0419 8.74923 41.2643 8l2.1294 3.549z" />,
    'Quote'
)

export default Quote
