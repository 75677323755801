/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const ShoppingCart = createSvgIcon(
    <>
        <path fill="#000" d="M0 0h30v30H0z" />
        <path
            fill="#fff"
            d="M11.6667 22.3334c.9204 0 1.6666-.7462 1.6666-1.6667 0-.9205-.7462-1.6666-1.6666-1.6666-.9205 0-1.6667.7461-1.6667 1.6666 0 .9205.7462 1.6667 1.6667 1.6667zM21.9333 9.80006c-.0666-.06666-.1333-.13334-.2666-.13334H9L8.66666 7.9334c-.06666-.13334-.2-.26665-.33335-.26665h-2C6.13334 7.66672 6 7.80006 6 8.00006c0 .2.13334.33334.33334.33334h1.73335l1.6 8.1333C9.93334 17.5334 10.8667 18.3334 12 18.3334h7.6667c.2 0 .3333-.1333.3333-.3333 0-.2-.1333-.3334-.3333-.3334H12c-.5333 0-1.0667-.2666-1.3333-.7333L20.4 15.6001c.1333 0 .2667-.1334.2667-.2667L22 10.0001s0-.13338-.0667-.20004zM18.3334 22.3334c.9204 0 1.6666-.7462 1.6666-1.6667 0-.9205-.7462-1.6666-1.6666-1.6666-.9205 0-1.6667.7461-1.6667 1.6666 0 .9205.7462 1.6667 1.6667 1.6667z"
        />
    </>,
    'ShoppingCart'
)

export default ShoppingCart
