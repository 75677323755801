import { AxiosError } from 'axios'

import { FORBIDDEN, NOT_FOUND } from '../../helpers/httpStatusCodes'
import notFoundResponse, { NotFoundResponse } from '../../helpers/notFoundResponse'

// xD
type ErrorHandlerResult = object

type ErrorHandler = (error: AxiosError) => ErrorHandlerResult | AxiosError

export const handleError =
    (handlers: ErrorHandler[]): ErrorHandlerResult =>
    (error: AxiosError) => {
        for (const handler of handlers) {
            const result = handler(error)

            if (result instanceof AxiosError) {
                continue
            }

            return result
        }

        throw error
    }

interface EmptyData extends ErrorHandlerResult {
    data: null
}

const emptyData = { data: null }

export const returnEmptyDataOnNotFound = (error: AxiosError): EmptyData | AxiosError => {
    if (error?.response?.status === NOT_FOUND) {
        return emptyData
    }

    return error
}

export const returnEmptyDataOnForbidden = (error: AxiosError): EmptyData | AxiosError => {
    if (error?.response?.status === FORBIDDEN) {
        return emptyData
    }

    return error
}

export const returnNextNotFoundResponseOnNotFound = (error: AxiosError): NotFoundResponse | AxiosError => {
    if (error?.response?.status === NOT_FOUND) {
        return notFoundResponse()
    }

    return error
}

export const returnNextNotFoundResponseOnForbidden = (error: AxiosError): NotFoundResponse | AxiosError => {
    if (error?.response?.status === FORBIDDEN) {
        return notFoundResponse()
    }

    return error
}

export const returnEmptyOn4xx = [returnEmptyDataOnForbidden, returnEmptyDataOnNotFound]

export const returnNextNotFoundResponseOn4xx = [
    returnNextNotFoundResponseOnForbidden,
    returnNextNotFoundResponseOnNotFound
]
