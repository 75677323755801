/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const ZenaSymbol = createSvgIcon(
    <>
        <path fill="#000" d="M0 0h32v32H0z" />
        <path
            fill="#fff"
            d="M15.8373 13.3141c0-1.6571 2.2093-2.2094 6.6281-3.3141-2.7477 1.5701-1.962 3.6982-1.4094 5.1946.2249.6093.4112 1.1139.3047 1.4335l-5.5234-3.314zM7 16.2759c.75949-.9874 3.0987-2.9621 6.3797-2.9621 2.1247 0 3.4304 1.1272 4.7464 2.2632 1.2243 1.0569 2.4575 2.1216 4.3676 2.2938.1518.0137.3006.0286.4461.0433.7889.0793 1.4831.1491 2.0602-.0433-.6076.9873-2.7342 3.0076-6.3797 3.1899-2.422.1211-3.7545-1.1425-5.0847-2.4039-1.1725-1.1119-2.3433-2.2222-4.25712-2.3809-.88676-.0736-2.27848 0-2.27848 0z"
        />
    </>,
    'ZenaSymbol'
)

export default ZenaSymbol
