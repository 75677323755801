/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const CheckMarkSmall = createSvgIcon(
    <path
        d="M20.707 9.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L12 16.586l7.293-7.293a1 1 0 011.414 0z"
        fill="#fff"
    />,
    'CheckMarkSmall'
)

export default CheckMarkSmall
