/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const Four = createSvgIcon(
    <path
        d="M10 20a9.935 9.935 0 01-7.071-2.929A9.935 9.935 0 010 10a9.935 9.935 0 012.929-7.071A9.935 9.935 0 0110 0a9.935 9.935 0 017.071 2.929A9.935 9.935 0 0120 10a9.935 9.935 0 01-2.929 7.071A9.935 9.935 0 0110 20zm0-18.438c-4.652 0-8.438 3.786-8.438 8.438 0 4.652 3.786 8.438 8.438 8.438 4.652 0 8.438-3.785 8.438-8.438 0-4.652-3.785-8.438-8.438-8.438zm3.662 10.203a.781.781 0 00-1.079.24c-.01.015-.982 1.513-2.622 1.513s-2.613-1.498-2.622-1.513a.781.781 0 10-1.318.839c.058.091 1.452 2.237 3.94 2.237s3.882-2.146 3.94-2.237a.781.781 0 00-.24-1.079zM5.235 9.492a.78.78 0 01-.504-1.379c.768-.646 1.57-.969 2.381-.964h1.052a.781.781 0 110 1.562H7.11c-.006 0-.894.196-1.372.598a.778.778 0 01-.502.183zm9.491-.039a.778.778 0 01-.476-.162c-.512-.395-1.004-.59-1.46-.58h-.993a.781.781 0 010-1.563l.969.001c.817-.016 1.638.289 2.437.904a.781.781 0 01-.477 1.4z"
        fill="#000"
    />,
    'Four'
)

export default Four
