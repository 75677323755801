/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const Circle = createSvgIcon(
    <path d="M 16 0 A 16 16 0 0 0 0 16 A 16 16 0 0 0 16 32 A 16 16 0 0 0 32 16 A 16 16 0 0 0 16 0 z " />,
    'Circle'
)

export default Circle
