/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const RtlHrLogo = createSvgIcon(
    // <svg width="90" height="30" viewBox="0 0 90 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <>
        <path
            d="M5.69403 26.8461V24.0793H2.46463V26.8461H1.65576V20.874H2.46463V23.4004H5.69403V20.874H6.5044V26.8461H5.69403Z"
            fill="#E31837"
        />
        <path
            d="M18.1324 26.8461L16.5812 24.3666H14.7216V26.8461H13.9112V20.874H16.7203C17.3916 20.874 17.9117 21.0252 18.2775 21.3261C18.6434 21.627 18.8264 22.0458 18.8264 22.5825C18.8264 23.027 18.6963 23.3989 18.4378 23.7013C18.1793 24.0037 17.8225 24.1942 17.3674 24.2728L19.0622 26.8446H18.1324V26.8461ZM18.013 22.5916C18.013 22.2438 17.895 21.9792 17.6592 21.7963C17.4233 21.6134 17.0831 21.5226 16.6402 21.5226H14.7216V23.7255H16.6749C17.1028 23.7255 17.4309 23.6257 17.6637 23.4277C17.8965 23.2281 18.013 22.9499 18.013 22.5916Z"
            fill="#E31837"
        />
        <path
            d="M28.6128 26.8461H27.7737L25.3365 20.874H26.1877L27.8402 25.0787L28.197 26.134L28.5523 25.0787L30.1973 20.874H31.05L28.6128 26.8461Z"
            fill="#E31837"
        />
        <path
            d="M41.3762 26.8461L40.6943 25.0998H37.9729L37.2865 26.8461H36.4474L38.8846 20.874H39.8038L42.2032 26.8461H41.3762ZM39.3336 21.4848L39.2958 21.6043C39.2247 21.8386 39.1204 22.1395 38.9828 22.5069L38.2208 24.4694H40.4539L39.6859 22.4993C39.6072 22.3043 39.5271 22.0836 39.4485 21.8371L39.3336 21.4848Z"
            fill="#E31837"
        />
        <path d="M50.6154 21.5364V26.8462H49.811V21.5364H47.7594V20.8757H52.667V21.5364H50.6154Z" fill="#E31837" />
        <path
            d="M64.2452 25.1986C64.2452 25.7489 64.0305 26.1752 63.5996 26.4776C63.1688 26.78 62.561 26.9312 61.7793 26.9312C60.3249 26.9312 59.4812 26.4247 59.2484 25.4148L60.0331 25.2575C60.1238 25.6158 60.3158 25.8804 60.6091 26.0482C60.9039 26.2161 61.3031 26.3007 61.8096 26.3007C62.3312 26.3007 62.7348 26.2115 63.0191 26.0316C63.3033 25.8517 63.4454 25.5886 63.4454 25.2409C63.4454 25.0459 63.4016 24.8886 63.3124 24.7662C63.2232 24.6452 63.0992 24.5454 62.9374 24.4653C62.7757 24.3867 62.5837 24.3202 62.3614 24.2657C62.1376 24.2113 61.8912 24.1538 61.6191 24.0919C61.1474 23.9875 60.789 23.8817 60.5441 23.7789C60.3007 23.6746 60.1072 23.5567 59.9651 23.4297C59.8244 23.3011 59.7171 23.15 59.6415 22.9791C59.5659 22.8068 59.5281 22.6087 59.5281 22.3849C59.5281 21.8739 59.7247 21.4793 60.1147 21.2026C60.5063 20.926 61.0657 20.7869 61.7944 20.7869C62.4733 20.7869 62.9904 20.8912 63.3502 21.0983C63.71 21.3054 63.961 21.6592 64.1046 22.1597L63.3063 22.2988C63.2187 21.9828 63.0523 21.753 62.8074 21.6108C62.561 21.4672 62.2223 21.3977 61.7854 21.3977C61.3076 21.3977 60.9448 21.4763 60.6938 21.635C60.4413 21.7938 60.3158 22.0296 60.3158 22.3426C60.3158 22.5255 60.3642 22.6782 60.4609 22.7977C60.5577 22.9171 60.6998 23.0199 60.8828 23.1031C61.0657 23.1862 61.4331 23.289 61.9804 23.41C62.1633 23.4523 62.3463 23.4962 62.5292 23.5385C62.7107 23.5824 62.886 23.6353 63.0523 23.6957C63.2187 23.7562 63.3759 23.8288 63.521 23.9104C63.6677 23.9921 63.7932 24.0934 63.9005 24.2113C64.0079 24.3307 64.0925 24.4698 64.153 24.6316C64.215 24.7904 64.2452 24.9809 64.2452 25.1986Z"
            fill="#E31837"
        />
        <path
            d="M75.3184 26.8461L72.9326 23.9644L72.1525 24.5586V26.8461H71.3436V20.874H72.1525V23.8661L75.0311 20.874H75.9851L73.4421 23.4685L76.3238 26.8461H75.3184Z"
            fill="#E31837"
        />
        <path
            d="M87.3545 26.8461L86.6726 25.0998H83.9512L83.2663 26.8461H82.4272L84.8644 20.874H85.7836L88.183 26.8461H87.3545ZM85.3119 21.4848L85.2741 21.6043C85.2031 21.8386 85.0988 22.1395 84.9597 22.5069L84.1962 24.4694H86.4307L85.6627 22.4993C85.5841 22.3043 85.5039 22.0836 85.4253 21.8371L85.3119 21.4848Z"
            fill="#E31837"
        />
        <path
            d="M29.2813 3.07153C29.2813 3.08514 29.2813 3.10933 29.2813 3.12294C30.1915 3.24389 31.0502 3.64908 31.7472 4.32641C32.2265 4.79056 32.5924 5.35601 32.8388 5.96531C35.2261 5.96531 37.9188 5.96531 37.9188 5.96531C37.9188 5.96531 37.9188 16.9583 37.9188 17.7264C38.6611 17.7324 48.6094 17.7793 49.3593 17.7838C49.3593 17.0128 49.3593 5.96531 49.3593 5.96531C49.3593 5.96531 57.2559 5.96531 58.0088 5.96531C58.0088 5.39381 58.0088 3.64454 58.0088 3.07153C57.2166 3.07153 30.0766 3.07153 29.2813 3.07153Z"
            fill="#58595B"
        />
        <path
            d="M72.3248 14.7191C72.3248 14.7191 72.3248 5.88969 72.3248 3.07153H59.9515C59.9515 6.21324 59.9515 17.0293 59.9515 17.7006C60.7528 17.7006 87.3863 17.7006 88.1816 17.7006C88.1816 17.2833 88.1816 15.7155 88.1816 14.7191C87.3908 14.7191 72.3248 14.7191 72.3248 14.7191Z"
            fill="#58595B"
        />
        <path
            d="M24.5596 12.3322H24.6125C24.5808 12.308 24.5611 12.2959 24.5611 12.2959L25.9445 12.3322C27.0905 12.3609 28.1942 11.9119 29.0514 11.0667C29.821 10.3138 30.3124 9.31748 30.4469 8.26671C30.4681 8.07318 30.4832 7.87815 30.4802 7.68614C30.4756 6.43429 29.9495 5.20813 29.0393 4.3267C28.1776 3.48911 27.0739 3.04461 25.9248 3.07333C25.9248 3.07333 3.01809 3.07333 1.65436 3.07333V17.701C2.6764 17.701 11.6813 17.701 12.4417 17.701C12.4417 16.8997 12.4463 12.6482 12.4463 12.6482H12.4493C12.4493 12.63 12.4493 12.6149 12.4493 12.6149H15.2796C15.2796 12.6149 21.058 17.515 21.2818 17.701C21.5418 17.701 30.0584 17.701 32.2264 17.701C30.5618 16.5308 24.5596 12.3322 24.5596 12.3322ZM20.1055 8.33323C20.0027 9.04382 19.5839 9.62893 18.952 9.92828L18.8688 9.97062L12.4433 9.97818V6.32392V5.90966L12.4206 5.90512H12.4478V5.87791H18.707L18.7766 5.90512C19.5597 6.18331 20.0874 6.93926 20.1312 7.8343C20.1388 8.00968 20.1282 8.17146 20.1055 8.33323Z"
            fill="#58595B"
        />
    </>,
    'RtlHrLogo'
)

export default RtlHrLogo
