/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const One = createSvgIcon(
    <path
        d="M10 20a9.935 9.935 0 01-7.071-2.929A9.935 9.935 0 010 10a9.935 9.935 0 012.929-7.071A9.935 9.935 0 0110 0a9.935 9.935 0 017.071 2.929A9.935 9.935 0 0120 10a9.935 9.935 0 01-2.929 7.071A9.935 9.935 0 0110 20zm0-18.438c-4.652 0-8.438 3.786-8.438 8.438 0 4.652 3.786 8.438 8.438 8.438 4.652 0 8.438-3.785 8.438-8.438 0-4.652-3.785-8.438-8.438-8.438zm2.343 14.532H7.657a1.763 1.763 0 01-1.538-.903 1.727 1.727 0 01.035-1.768c.566-.92 1.692-2.017 3.846-2.017s3.28 1.097 3.845 2.017c.336.546.35 1.207.035 1.768-.31.557-.9.903-1.537.903zM10 12.969c-1.15 0-1.996.428-2.514 1.272a.177.177 0 00-.003.188.193.193 0 00.174.102h4.686a.193.193 0 00.174-.102.177.177 0 00-.003-.188c-.518-.844-1.364-1.272-2.514-1.272zm-3.438-2.813A2.346 2.346 0 014.22 7.812 2.346 2.346 0 016.562 5.47a2.346 2.346 0 012.344 2.343 2.346 2.346 0 01-2.344 2.344zm0-3.125a.782.782 0 000 1.563.782.782 0 000-1.563zm6.875 3.125a2.346 2.346 0 01-2.343-2.344 2.346 2.346 0 012.344-2.343 2.346 2.346 0 012.343 2.343 2.346 2.346 0 01-2.344 2.344zm0-3.125a.782.782 0 000 1.563.782.782 0 000-1.563z"
        fill="#000"
    />,
    'One'
)

export default One
