import Script from 'next/script'
import PropTypes from 'prop-types'

// import { createDidomiConnectedScriptHandler } from '../createDidomiConnectedScriptHandler'

// dotmetrics IDs per section
export const dotmetricsIDs = {
    other: { id: 4432 },
    danas: { id: 318 },
    hot: { id: 322 },
    magazin: { id: 321 },
    naslovnica: { id: 208 },
    sport: { id: 320 },
    webcafe: { id: 319 },
    zena: { id: 15343 }
}

function Dotmetrics({ category }) {
    const { id } = dotmetricsIDs[category] || dotmetricsIDs.other

    // INFO: if consent required leave it for later
    // const html = createDidomiConnectedScriptHandler(
    //     'ipsosmori-TCJ9gJ9A',
    //  //     'c:dotmetrics-tde3FmPf',
    //     `(function() {
    //         window.dm=window.dm||{AjaxData:[]};
    //         window.dm.AjaxEvent=function(et,d,ssid,ad){
    //             dm.AjaxData.push({et:et,d:d,ssid:ssid,ad:ad});
    //             if(typeof window.DotMetricsObj != 'undefined') {DotMetricsObj.onAjaxDataUpdate();}
    //         };
    //         var d=document,
    //         h=d.getElementsByTagName('head')[0],
    //         s=d.createElement('script');
    //         s.type='text/javascript';
    //         s.async=true;
    //         s.src='https://script.dotmetrics.net/door.js?id=${id}';
    //         h.appendChild(s);
    //     }());`
    // )

    // INFO: consent not required for now

    if (typeof window !== 'undefined' && window.dm) {
        // console.log('dm virtual', id)
        window.dm.AjaxEvent('pageview', null, id)
        return null
    }

    const html = `(function() {
        window.dm=window.dm||{AjaxData:[]};
        window.dm.AjaxEvent=function(et,d,ssid,ad){
            dm.AjaxData.push({et:et,d:d,ssid:ssid,ad:ad})
            dm.AjaxData.splice(1); // remove all but the last event
            if(typeof window.DotMetricsObj != 'undefined') {DotMetricsObj.onAjaxDataUpdate();}
        };
        var d=document,
        h=d.getElementsByTagName('head')[0],
        s=d.createElement('script');
        s.type='text/javascript';
        s.async=true;
        s.src='https://script.dotmetrics.net/door.js?id=${id}';
        h.appendChild(s);
    }());`

    // console.log('dm create', id)
    return (
        <Script
            id="dotmetrics"
            key="dotmetrics"
            defer
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
                __html: html
            }}
        />
    )
}

Dotmetrics.propTypes = {
    category: PropTypes.string
}

Dotmetrics.defaultProps = {
    category: 'other'
}

export default Dotmetrics
