/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const User = createSvgIcon(
    <path d="m 16,0 c -5.104059,0 -9.2617186,4.1576594 -9.2617186,9.2617188 0,3.4706672 1.9460944,6.4676892 4.7812496,8.0546872 C 4.9195646,19.228858 0.68359392,25.284484 0.68359394,32 H 2.683594 C 2.683594,24.864792 7.9290366,18.683594 16,18.683594 24.070922,18.683594 29.316406,24.8648 29.316406,32 h 2 c 0,-6.715524 -4.235982,-12.771143 -10.835938,-14.683594 2.835154,-1.586996 4.78125,-4.584014 4.78125,-8.0546872 C 25.261718,4.1576506 21.103988,0 16,0 Z m 0,2 c 4.023092,0 7.261718,3.238547 7.261718,7.2617188 0,4.0231722 -3.238626,7.2656252 -7.261718,7.2656252 -4.02318,0 -7.2617186,-3.242445 -7.2617186,-7.2656252 C 8.7382814,5.2385382 11.97682,2 16,2 Z" />,
    'User'
)

export default User
