/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const ChevronLeft = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.707 11.293l-1.414 1.414L42.586 32 23.293 51.293l1.414 1.414L45.414 32 24.707 11.293z"
    />,
    'ChevronLeft'
)

export default ChevronLeft
