/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const Close = createSvgIcon(
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.22 9.22a.75.75 0 011.06 0l13 13a.75.75 0 11-1.06 1.06l-13-13a.75.75 0 010-1.06z"
            fill="#141313"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.28 9.22a.75.75 0 00-1.06 0l-13 13a.75.75 0 101.06 1.06l13-13a.75.75 0 000-1.06z"
            fill="#141313"
        />
    </>,
    'Close'
)

export default Close
