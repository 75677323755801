/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const Search = createSvgIcon(
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5 24.7c6.4617 0 11.7-5.2383 11.7-11.7 0-6.46173-5.2383-11.7-11.7-11.7C12.0383 1.3 6.80002 6.53827 6.80002 13c0 6.4617 5.23828 11.7 11.69998 11.7zm0 1.3c7.1797 0 13-5.8203 13-13s-5.8203-13-13-13S5.50002 5.8203 5.50002 13 11.3203 26 18.5 26z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.575745 30.0757l8.999995-9 .84856.8485-9.00003 9-.848525-.8485z"
        />
    </>,
    'Search'
)

export default Search
