/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const SocialTikTok = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16C0 7.163 7.163 0 16 0C24.836 0 32 7.163 32 16C32 24.836 24.836 32 16 32C7.163 32 0 24.836 0 16ZM19.4771 8.46875C19.4771 10.1482 20.8435 11.5146 22.5229 11.5146C22.7818 11.5146 22.9917 11.7245 22.9917 11.9833V14.4938C22.9917 14.7527 22.7818 14.9625 22.5229 14.9625C21.4482 14.9625 20.4101 14.7029 19.4772 14.2055V18.7615C19.4772 21.65 17.1271 24 14.2386 24C11.35 24 9.00003 21.65 9 18.7615C9 15.8729 11.35 13.5229 14.2385 13.5229C14.4974 13.5229 14.7073 13.7327 14.7073 13.9916V16.5021C14.7073 16.7609 14.4974 16.9708 14.2385 16.9708C13.2512 16.9708 12.4479 17.7741 12.4479 18.7615C12.4479 19.7488 13.2512 20.5521 14.2385 20.5521C15.2259 20.5521 16.0292 19.7488 16.0292 18.7615V8.46875C16.0292 8.20988 16.2391 8 16.4979 8H19.0084C19.2673 8 19.4771 8.20988 19.4771 8.46875Z"
    />,
    'SocialTikTok'
)

export default SocialTikTok
